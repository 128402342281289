angular.module('cpformplastApp')
  .controller('ProductionReportModal', function ($scope, Util, appConfig, $http, SubjobProductionReport, Notification, $modalInstance, subjobSchedule) {

    $scope.subjobSchedule = subjobSchedule;

    // Initial values are used for input validation; The save button is disabled
    // if the value is the same as the initial value.
    $scope.initialQuantityPerSkid = subjobSchedule.subjob.job.item.quantityPerSkid;
    $scope.initialQuantityPerBox = subjobSchedule.subjob.job.item.quantityPerBox;
    
    // Doing this prevent the other references to `quantityPerSkid` and `quantityPerBox`
    // to change along when we change the inputs' contents. We only change other
    // references when saving.
    $scope.quantityPerSkid = $scope.initialQuantityPerSkid
    $scope.quantityPerBox = $scope.initialQuantityPerBox;

    $scope.updateSubjobSchedule = () => {
			const url = appConfig.api.url + '/api/jobs/' + subjobSchedule.subjob.job.id + '/subjobs/' + subjobSchedule.subjob.id + '/schedules/' + subjobSchedule.id + '?mode=single';
      $http.put(url, { startBoxIndex: subjobSchedule.startBoxIndex })
        .then(res => {
          subjobSchedule.startBoxIndex = res.data.startBoxIndex;
          subjobSchedule.producedQuantity = res.data.producedQuantity;
        })
        .catch(error => { Notification.error('Un problème est survenu'); })
    };

    $scope.createProductionReport = function(boxIndex) {
      const url = appConfig.api.url + "/api/jobs/" + $scope.subjobSchedule.subjob.job.id + "/subjobs/" + $scope.subjobSchedule.subjob.id + "/schedules/" + $scope.subjobSchedule.id;
      $http.post(url, { boxIndex: boxIndex })
        .then(res => {
          subjobSchedule.productionReports.push(new SubjobProductionReport(res.data));
          subjobSchedule.producedQuantity = (res.data.boxIndex - subjobSchedule.startBoxIndex) * subjobSchedule.subjob.job.item.quantityPerBox;
        })
        .catch(error => { Notification.error('Un problème est survenu'); })
    };

    $scope.getElegantReportDate = function(report) {
      return Util.getDateTimeString(report.createdAt);
    };

    $scope.updateItem = () => {
      const { id, name } = $scope.subjobSchedule.subjob.job.item;
      
      const quantityPerSkid = $scope.quantityPerSkid;
      const quantityPerBox = $scope.quantityPerBox;

      const url = `${appConfig.api.url}/api/items/${id}`;
      return $http.put(url, { quantityPerSkid, quantityPerBox })
        .then(() => {
          // Update initial values
          $scope.initialQuantityPerSkid = quantityPerSkid;
          $scope.initialQuantityPerBox = quantityPerBox;
          
          // Update all references
          $scope.subjobSchedule.subjob.job.item.quantityPerSkid = quantityPerSkid;
          $scope.subjobSchedule.subjob.job.item.quantityPerBox = quantityPerBox;

          Notification.success(`L'item <strong>${name}</strong> a été mis à jour`);
        })
        .catch(() => {
          Notification.error("Un problème est survenu");
        });
    };

    $scope.updateItemDisabled = () => {
      const valuesNotEmpty = $scope.quantityPerSkid != undefined && $scope.quantityPerBox != undefined;
      const canUpdateSkid = $scope.quantityPerSkid !== $scope.initialQuantityPerSkid;
      const canUpdateBox = $scope.quantityPerBox !== $scope.initialQuantityPerBox;
      return !valuesNotEmpty || (!canUpdateSkid && !canUpdateBox);
    }

  });
